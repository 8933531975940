import { breakpoints, g } from '@pretto/bricks/components/layout'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import { Picto as PictoType } from '@pretto/picto'

import styled from 'styled-components'

export type BlockquoteItemProps = {
  content: string
  picto: PictoType
  title?: string
}

export const BlockquoteItem: React.FC<BlockquoteItemProps> = ({ content, picto, title, ...props }) => (
  <BlockquoteItemContainer {...props}>
    <EntryPicto>
      <Picto as={picto} />
    </EntryPicto>

    <div>
      {title && <EntryTitle>{title}</EntryTitle>}
      <EntryText allowedTypes={['strong', 'a']}>{content}</EntryText>
    </div>
  </BlockquoteItemContainer>
)

const BlockquoteItemContainer = styled.div`
  display: flex;
  gap: ${g(2)};

  & + & {
    margin-top: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    gap: ${g(3)};
  }
`

const EntryPicto = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.neutral5};
  border-radius: 50%;
  height: ${g(6)};
  width: ${g(6)};
`

const Picto = styled.svg`
  height: ${g(3)};
  width: ${g(3)};
  color: ${({ theme }) => theme.colors.neutral2};
`

const EntryTitle = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(2)};
`

const EntryText = styled(RichContent)`
  ${({ theme }) => theme.typos.body4};
`
