import { BlockquoteItemProps } from '@pretto/website/src/components/BlockquoteItem/BlockquoteItem'
import { FormatedImage } from '@pretto/website/src/components/FormatedImage/FormatedImage'
import { slugify } from '@pretto/website/src/templates/blocs/lib/slugify'

import * as S from './RowCarousel.styles'

export type RowCarouselProps = {
  entries?: BlockquoteItemProps[]
  image: string
  isColored?: boolean
  isReversed?: boolean
  suptitle?: string
  title: string
}

export const RowCarousel: React.FC<RowCarouselProps> = ({
  entries,
  image,
  isColored = false,
  isReversed = false,
  suptitle,
  title,
  ...props
}) => (
  <S.NewBlocRow isColored={isColored} isReversed={isReversed} {...props}>
    <S.ContentWrapper>
      {suptitle && <S.Suptitle>{suptitle}</S.Suptitle>}

      <S.Title id={slugify(title)}>{title}</S.Title>

      {entries && entries.map((entry, index) => <S.BlockquoteItem {...entry} key={index} />)}
    </S.ContentWrapper>

    <S.ImageWrapper>
      <FormatedImage src={image} format="rectangle" isRounded />
    </S.ImageWrapper>
  </S.NewBlocRow>
)
