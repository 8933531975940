import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

type Sizes = {
  mobileS?: string
  mobileM?: string
  mobileL?: string
  tablet?: string
  laptop?: string
  desktop?: string
}

export type FormatedImageProps = {
  alt?: string
  format: 'square' | 'rectangle'
  isRounded?: boolean
  src: string
  sizes?: Sizes
}

const DEFAULT_SIZES = {
  tablet: '400px',
  mobileL: '100vw',
}

const FORMAT_RATIO = {
  square: '1580 / 1580',
  rectangle: '1920 / 1568',
}

const getImageSizes = (sizes: Sizes): string => {
  const sizesOrder: (keyof Sizes)[] = ['desktop', 'laptop', 'tablet', 'mobileL', 'mobileM', 'mobileS']
  const numberOfValues = Object.keys(sizes).length

  return sizesOrder.reduce((acc: string, size: keyof Sizes, index: number): string => {
    if (sizes[size]) {
      if (numberOfValues === 1 || index === numberOfValues + 1) {
        acc += `${sizes[size]}`
      } else {
        acc += `(min-width: ${breakpoints[size]}) ${sizes[size]}, `
      }
    }
    return acc
  }, '')
}

export const FormatedImage: React.FC<FormatedImageProps> = ({ alt, format, isRounded, src, sizes, ...props }) => {
  const cloudinaryConfig = {
    options: {
      crop: 'auto',
    },
    sizes: getImageSizes(sizes || DEFAULT_SIZES),
    srcSetBreakpoints: [
      numeralBreakpoints.mobileS,
      numeralBreakpoints.mobileM,
      numeralBreakpoints.mobileL,
      numeralBreakpoints.tablet,
      numeralBreakpoints.laptop,
      numeralBreakpoints.desktop,
    ],
  }

  return (
    <ImageContainer $aspectRatio={FORMAT_RATIO[format]} $isRounded={isRounded}>
      <Image $isRounded={isRounded} alt={alt} path={src} {...cloudinaryConfig} {...props} />
    </ImageContainer>
  )
}

type ContainerProps = {
  $aspectRatio: string
  $isRounded?: boolean
}

const ImageContainer = styled.div<ContainerProps>`
  width: 100%;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  border-radius: ${({ $isRounded }) => $isRounded && g(1)};
  overflow: hidden;
`

const Image = styled(BaseImage)`
  display: block;
  border-radius: ${({ $isRounded }) => $isRounded && g(1)};
  height: 100%;
  margin: auto;
`
